import React, { Component } from 'react';

class GlobalStateKeeper extends Component {
  state = {
    cart: {}
  };

  componentDidMount() {
    const savedCart = JSON.parse(localStorage.getItem('volovodCart'));
    if (savedCart) {
      this.setState({ cart: savedCart });
    }
  }

  addToCart = good => {
    const { cart } = this.state;
    const newGood = cart[good.id]
      ? { ...good, amount: cart[good.id].amount + 1 }
      : { ...good, amount: 1 };
    const newCart = { ...cart, [good.id]: newGood };
    cart[good.id] = cart[good.id]
      ? { ...good, amount: cart[good.id].amount + 1 }
      : { ...good, amount: 1 };
    this.setState({ cart: newCart });
    localStorage.setItem('volovodCart', JSON.stringify(newCart));
  };

  removeItem = id => {
    const { cart } = this.state;
    const { [id]: omit, ...newCart } = cart;
    this.setState({ cart: newCart });
    localStorage.setItem('volovodCart', JSON.stringify(newCart));
  };

  setAmount = (id, newAmount) => {
    const { cart } = this.state;
    const { [id]: item, ...rest } = cart;
    const amount = newAmount < 1 ? 1 : newAmount;
    const newItem = { ...item, amount };
    const newCart = { ...rest, [id]: newItem };
    this.setState({ cart: newCart });
    localStorage.setItem('volovodCart', JSON.stringify(newCart));
  };

  clearCart = () => {
    this.setState({ cart: {} });
    localStorage.removeItem('volovodCart');
  };

  render() {
    const children = React.Children.map(this.props.children, (child, index) => {
      return React.cloneElement(child, {
        index,
        cart: this.state.cart,
        addToCart: this.addToCart,
        removeItem: this.removeItem,
        clearCart: this.clearCart,
        setAmount: this.setAmount
      });
    });
    return <>{children}</>;
  }
}

export default GlobalStateKeeper;
